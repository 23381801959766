import React from 'react'

function Landing() {
    return (
        <>
        {window.innerWidth > 900 ? 
        <div className="head-bg">
            <div className="logo"></div>
            <span data-aos="fade-right" data-aos-delay = "500">DREAM.</span>
            <span data-aos="fade-right" data-aos-delay = "1000">DISCOVER.</span>
            <span data-aos="fade-right" data-aos-delay = "1500">DELIVER</span>
        </div>
        :
        <div className="head-bg">
            <div className="logo"></div>
            <span data-aos="fade-right" data-aos-delay = "500"><span>D</span><span>REAM</span></span>
            <span data-aos="fade-right" data-aos-delay = "1000"><span>D</span><span>ISCOVER</span></span>
            <span data-aos="fade-right" data-aos-delay = "1500"><span>D</span><span>ELIVER</span></span>
        </div>
        }

        </>
    )
}

export default Landing
