/* eslint-disable */
import React, { useEffect, useRef } from "react";

const ImageModal = ({ modal, setModal }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className={`image-modal ${modal ? "open" : ""}`}>
      <button className="close-btn">x</button>
      <img src={modal} alt="project-modal-img" ref={modalRef} />
    </div>
  );
};

export default ImageModal;
