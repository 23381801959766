/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { ProjectInfo } from "./ProjectInfo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Images from "./Images";
import { Link } from "react-router-dom";
import Modal from "./ImageModal";

function ProjectPage(get) {
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);

  const settings = {
    centerMode: true,
    slidesToShow: 2,
    dots: true,
    adaptiveHeight: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  function getProject(id) {
    for (let i = 0; i < ProjectInfo.length; i++) {
      if (ProjectInfo[i].id == id) {
        return ProjectInfo[i];
      }
    }
  }

  useEffect(() => {
    setProject(getProject(get.match.params.id));
    setLoading(false);
  }, [get.match.params.id]);

  console.log([...Array(project.photos)]);

  return (
    <>
      <div className="project-info-c">
        <span>
          <Link to={"/projects"}>Projects</Link> / {project.category} /
        </span>
        <h1>{project.name}</h1>
        <div className="project-slider">
          {loading ? (
            "loading..."
          ) : (
            <Slider {...settings}>
              {[...Array(project.photos).keys()].map(image => (
                <Images
                  key={image + 1}
                  category={project.category}
                  pathName={project.pathName}
                  i={image + 1}
                  setModal={setModal}
                />
              ))}
            </Slider>
          )}
          <p>{project.desc}</p>
        </div>
      </div>
      {modal && <Modal modal={modal} setModal={setModal} />}
    </>
  );
}

export default ProjectPage;
