import React from 'react'

function GIT() {
    return (
            <div className="contact-info">
                <div className="phone">
                    <span>PHONE</span>
                    <span>+91 8952872487</span><br/>
                    <a href="tel:+918952872487"><button className="contact-btn"><i className="fas fa-phone-alt"></i>CALL US</button></a>
                </div>
                <div className="address">
                    <span>ADDRESS</span>
                    <span>Gujar ki Thadi, Jaipur, Rajasthan 302019, India</span><br/>
                    <a href="https://maps.google.com/maps?ll=26.883613,75.761624&z=17&t=m&hl=en&gl=IN&mapclient=embed&daddr=486%2C%20Govind%20Marg%20Katewa%20Nagar%2C%20Devi%20Nagar%2C%20Shyam%20Nagar%20Jaipur%2C%20Rajasthan%20302019@26.883325,75.761764"><button className="contact-btn"><i className="fas fa-map-marker-alt"></i>OPEN MAP</button></a>
                </div>
                <div className="email">
                    <span>EMAIL</span>
                    <span>info@tatvastu.in</span><br/>
                    <a href="mailto:info@tatvastu.in"><button className="contact-btn"><i className="fas fa-envelope"></i>MAIL US</button></a>
                </div>
            </div>
    )
}

export default GIT
