import React from 'react'
import Testimonial from './Testimonial'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import yash from './images/yash.jpg'
import nishantc from './images/nishantc.jpg'
import rajeshnama from './images/rajeshnama.jpg'
import skmeena from './images/skmeena.jpg'
import dp from './images/dp.jpg'

function Testimonials() {

    const settings = {
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        speed: 1000,
        autoplay: true,
        cssEase: "ease-in-out",
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

    return (
        <div className="testimonials-c">
            <Slider {...settings}>
                <Testimonial dp={rajeshnama} text={"Sonika has great ideas and I love her style. Sonika really tries to match up your own style and collaborates that with her own. I didn't know where to start with my project and she happily put my visions to life. She is also very communicative and persistent in making sure you're 100% satisfied with the outcome. A quality design that suited our taste and pocket and not only brought out creativity but also met all the functional needs of my family for my new apartment. I would highly recommend tatvastu for giving life to your spaces!!!"} name={'Rajesh Nama'} />
                <Testimonial dp={nishantc} text={"Sonika Jain and her team from Tatvastu really took the time to understand my style and helped me express myself in my home. The team's attention to detail was phenomenal. They did an amazing Job blending my old and new furniture and decor. I'd definitely recommend them."} name={'Nishant Chaturvedi'} />
                <Testimonial dp={skmeena} text={"Truly satisfied  and super happy with tatvastu. The team was professional and always available and responsive. A good company to work with!!"} name={'S.K. Meena'} />
                <Testimonial dp={dp} text={'Superb! I recommend tatvastu  as a go to firm for all your architectural and interior needs. They have a full line of services and the Founder/owner is a creative and task oriented individual. I would definitely recommend them!'} name={'Manukunj'} />
                <Testimonial dp={yash} text={"A very hard working and professional team who approach a project like it's their own. Got my house renovated last year. Highly recommended."} name={'Yash'} />
            </Slider>
        </div>
    )
}

export default Testimonials