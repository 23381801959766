import React from 'react'
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

function Footer() {
    return (
            <div className="footer-container">
                <div className="footer-grid">
                    <div className="footer-tatvastu">
                        <div className="footer-logo"></div><h3>tatvastu</h3>
                        <h4>DREAM . DISCOVER . DELIVER</h4>
                    </div>
                    <div className="footer-explore">
                        <h5>EXPLORE</h5>
                        <span><Link to={'/'}><i className="fas fa-home"></i>HOME</Link></span>
                        <span><HashLink to="/#about"><i className="fas fa-bullseye"></i> ABOUT</HashLink></span>
                        <span><Link to={'/projects'}><i className="fas fa-list-ul"></i>PROJECTS</Link></span>
                        <span><Link to={'/people'}><i className="fas fa-user-friends"></i>PEOPLE</Link></span>
                        <span><Link to={'/contact'}><i className="fas fa-envelope"></i>CONTACT</Link></span>
                    </div>
                    <div className="footer-follow">
                        <h5>FOLLOW</h5>
                        <span><i className="fab fa-facebook-f"></i><a href="https://www.facebook.com/tatvastu">FACEBOOK</a></span>
                        <span><i className="fab fa-instagram"></i><a href="https://www.instagram.com/tatvastu">INSTAGRAM</a></span>
                    </div>
                    <div className="footer-contact">
                        <h5>CONTACT</h5>
                        <span><i className="fas fa-phone-alt"></i><a href="tel:+918952872487">CALL US</a></span>
                        <span><i className="fas fa-envelope"></i><a href="mailto:info@tatvastu.in" rel="noopener noreferrer" target="_blank"> MAIL US</a></span>
                        <span><i className="fas fa-map-marker-alt"></i><a href="https://maps.google.com/maps?ll=26.883613,75.761624&z=17&t=m&hl=en&gl=IN&mapclient=embed&daddr=486%2C%20Govind%20Marg%20Katewa%20Nagar%2C%20Devi%20Nagar%2C%20Shyam%20Nagar%20Jaipur%2C%20Rajasthan%20302019@26.883325,75.761764">GET DIRECTIONS</a></span>
                    </div>
                </div>
                <center><hr size="1px" width="100%" /></center>
                <div className="footer-info-grid">
                    <span><i className="far fa-copyright"></i> tatvastu - 2021 | All Rights Reserved</span>
                    <span></span>
                    <span></span>
                    <span><a href="#top">Back To Top</a></span>
                </div>
            </div>	
    )
}

export default Footer
