import React from 'react'
import Form from './Form'
import GIT from './GIT'

function Contact() {
    return (
        <>
            <div className="contact-container">
                <span>GET IN TOUCH WITH US</span><br/>
                    <GIT />
                    <br/>
                    <br/>
                    <span>LEAVE A MESSAGE</span><br/>
                    <Form />
                    <span>CONNECT WITH US</span><br/>
                    <div className="contact-social">
                        <div className="social">
                            <ul>
                                <li><a href="https://www.facebook.com/tatvastu"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/tatvastu"><i className="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <br/>
                </div>
                <div className="contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14234.541376393374!2d75.7617576!3d26.8833244!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x432ce76bdb36f8a5!2sTatvastu%20-%20Architecture%20%7C%20Interiors%20%7C%20Product%20%7C%20Landscape!5e0!3m2!1sen!2sin!4v1604000921908!5m2!1sen!2sin"
                    width="100%" height="300" frameBorder="0" style={{border :0}} allowFullScreen="" aria-hidden="false" tabIndex="0" title="map"></iframe>
                </div>
        </>
    )
}

export default Contact
