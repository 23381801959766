import React from 'react'
import arch from '../../images/arch.png'
import id from '../../images/id.png'
import pmc from '../../images/pmc.png'
import product from '../../images/product.png'

function Services() {
    return (
        <>
            <h2 className="services-h">REASONS TO COUNT ON US!</h2>
            <div className="services">
                <div><img src={arch} alt="" /> <span>Architectural Design</span></div>
                <div><img src={id} alt="" /><span>Interior Design</span></div>
                <div><img src={pmc} alt="" /><span>Project management</span></div>
                <div><img src={product} alt="" /><span>Product Design</span></div>
            </div>
        </>
    )
}

export default Services
