import React from 'react'

function Header(props) {
    return (
        <div className="header">
            <div></div>
            <span>{props.text}</span>
        </div>
    )
}

export default Header
