import React from 'react'

function Testimonial(props) {
    return (
        <>
        <div className="testimonial">
            <i class="fas fa-quote-left"></i>
            <img src={props.dp} alt="" />
            <p>{props.text}</p>
            <span>{props.name}</span>
        </div>
        </>
    )
}

export default Testimonial
