import React from 'react'
import { Link } from 'react-router-dom';

function ProjectTile(props) {
    return (
        <>
            <div data-aos="fade-up" className={props.class + ' project-tile'} style={{backgroundImage: 'url('+process.env.PUBLIC_URL + props.url + ')'}} >
                <span>{props.name}</span>
                <span>{props.desc}</span>
                <span><Link to={'/'+props.category+'/'+props.pathName+'/'+props.id} >View {'>'}</Link></span>
            </div>
        </>
    )
}

export default ProjectTile
