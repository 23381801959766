import React from "react";
import ProjectTile from "./../projects/ProjectTile";
import { ProjectInfo as P } from "../projects/ProjectInfo";

function Grid() {
  return (
    <div class="grid-container">
      <ProjectTile
        url={"commercial/Shades/4.jpg"}
        category={P[0].category}
        pathName={P[0].pathName}
        id={P[0].id}
        desc={P[0].desc}
        name={P[0].name}
      />
      <ProjectTile
        url={"residential/house-no-92/9.jpg"}
        category={P[10].category}
        pathName={P[10].pathName}
        id={P[10].id}
        class="card-tall"
        desc={P[10].desc}
        name={P[10].name}
      />
      <div class="quote">
        <i class="fas fa-quote-left"></i>Rendering innovation and adding value to our
        projects, is our prime focus when it comes to planning structures or defining
        interiors.
        <i class="fas fa-quote-right"></i>
      </div>
      <ProjectTile
        url={"residential/mid-century-abode/21.jpg"}
        category={P[11].category}
        pathName={P[11].pathName}
        id={P[11].id}
        class="card-tall card-wide"
        desc={P[11].desc}
        name={P[11].name}
      />
      <ProjectTile
        url={"residential/panashe/3.jpg"}
        category={P[12].category}
        pathName={P[12].pathName}
        id={P[12].id}
        desc={P[12].desc}
        name={P[12].name}
      />
      <div class="quote">
        <i class="fas fa-quote-left"></i>Our design process involves a systematic approach
        towards the intricate details of client's vision, thus creating a soulful human
        experience.
        <i class="fas fa-quote-right"></i>
      </div>
      <div class="quote">
        <i class="fas fa-quote-left"></i>We fixate on bringing sustainability in every
        design we execute via copious judicious techniques, and attemp to contribute our
        bit into detoxifying mother nature.<i class="fas fa-quote-right"></i>
      </div>
      <ProjectTile
        url={"residential/Shree-Villa/3.jpg"}
        category={P[9].category}
        pathName={P[9].pathName}
        id={P[9].id}
        desc={P[9].desc}
        name={P[9].name}
        class="card-tall"
      />
      <ProjectTile
        url={"commercial/Parth-Publishers/8.jpg"}
        category={P[2].category}
        pathName={P[2].pathName}
        id={P[2].id}
        desc={P[2].desc}
        name={P[2].name}
      />
      <ProjectTile
        url={"residential/Eclectic-House/9.jpg"}
        category={P[8].category}
        pathName={P[8].pathName}
        id={P[8].id}
        class="card-tall card-wide"
        desc={P[8].desc}
        name={P[8].name}
      />
    </div>
  );
}

export default Grid;
