export const ProjectInfo = [
  {
    id: 1,
    name: "Shades Salon",
    pathName: "Shades",
    category: "commercial",
    photos: 13,
    desc: 'The salon at Barkat nagar, Jaipur is a franchise of leading salon brand "Shades" spread in 2000 sq.ft. consists of salon, spa and bridal makeover.',
  },
  {
    id: 2,
    name: "Manukunj",
    pathName: "Manukunj-Boutique",
    category: "commercial",
    photos: 3,
    desc: "A small boutique space having variety of displays for womenswear, jewellery and accessories.",
  },
  {
    id: 3,
    name: "Parth Publishers",
    pathName: "Parth-Publishers",
    category: "commercial",
    photos: 13,
    desc: "A buoyant workspace interiors for Rajasthan' leading publishing brand inculcating true work spirit.",
  },
  {
    id: 4,
    name: "Wood Warehouse",
    pathName: "Mr.-Dharmendra-Commercial",
    category: "commercial",
    photos: 4,
    desc: "A G+2 commercial cum residential building located in bharatpur, Rajasthan.",
  },
  {
    id: 5,
    name: "Vivid Nest",
    pathName: "Bharti-Residence",
    category: "residential",
    photos: 13,
    desc: "A 3BHK apartment located near hirapura, Jaipur having carpet area of 2100 sq.ft.",
  },
  {
    id: 6,
    name: "Jagdish",
    pathName: "Jagdish-Sharma-Residence",
    category: "residential",
    photos: 11,
    desc: "A G+1 residence located in jagatpura, Jaipur consists of 4 bedrooms and required amenities for a nuclear family.",
  },
  {
    id: 7,
    name: "Kaleidoscope",
    pathName: "Sharma-Residence",
    category: "residential",
    photos: 11,
    desc: "The high end residence is situated in civil lines, Jaipur spread in approx. 12000 sq.ft. area structurally divided into G+4 floors having 8 bedrooms and all the relevant luxuries and amenities.",
  },
  {
    id: 8,
    name: "House No. 72",
    pathName: "Chaturvedi-Residence",
    category: "residential",
    photos: 11,
    desc: "A G+1 residential space situated in jadon nagar, Jaipur having 4 bedrooms and all useful amenities for a family of 4 members.",
  },
  {
    id: 9,
    name: "Eclectic Condo",
    pathName: "Eclectic-House",
    category: "residential",
    photos: 36,
    desc: "A sophisticated blend of modern contemporary and eclecticism for a multigenerational family",
  },
  {
    id: 10,
    name: "Shree Villa",
    pathName: "Shree-Villa",
    category: "residential",
    photos: 16,
    desc: "",
  },
  {
    id: 11,
    name: "House No. 92",
    pathName: "house-no-92",
    category: "residential",
    photos: 14,
    desc: "Modern contemporary house with a twist of traditional elements to give a sense of character and uniqueness to space",
  },
  {
    id: 12,
    name: "Mid Century Abode",
    pathName: "mid-century-abode",
    category: "residential",
    photos: 22,
    desc: "A 2BHK apartment for a nuclear family with a mid century modern style interiors keeping in mind 'COMPLETE FUNCTIONALITY + AESTHATICS + OPENNESS AS AN OPERATING CONCEPT + MAXIMUM USAGE OF SPACE + USER REQUIREMENTS'",
  },
  {
    id: 13,
    name: "Panashe 101",
    pathName: "panashe",
    category: "residential",
    photos: 18,
    desc: "",
  },
  {
    id: 14,
    name: "Pastels Splash",
    pathName: "pastels-splash",
    category: "residential",
    photos: 22,
    desc: "",
  },
];
