import React from 'react'

function FormInput(props) {
    return (
        <div className="col">
            <div className={props.type === "textarea" ? "inputbox textarea" : "inputbox"}>
                {props.type === "textarea" ?
                <textarea name={props.name} ref={props.reference} required/>
                :
                <input type="text" name={props.name} ref={props.reference} required />
                }
                <span className="text">{props.text}</span>
                <span className="line"></span>
                {props.error && <span className="error">{props.errorName}</span>}
            </div>
        </div>
    )
}

export default FormInput