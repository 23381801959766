import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

function Nav() {

    const [menu, setMenu] = useState(false)

    const toggle = () => {
        setMenu(prev => !prev)
    }

    return (
            <nav className={menu && "active"}>
                <div className="logo-text">
                    <Link to={'/'}><div className="nav-logo"><span role="img" aria-label="logo"></span></div></Link>
                    <h3><Link to={'/'}>tatvastu</Link></h3>
                </div>
                <ul>
                    <li className="vanish fade"><Link to={'/'}><i className="fas fa-home"></i> HOME</Link></li>
                    <li className="vanish fade"><HashLink to="/#about"><i className="fas fa-bullseye"></i> ABOUT</HashLink></li>
                    <li className="vanish fade"><Link to={'/projects'}><i className="fas fa-list-ul"></i> PROJECTS</Link></li>
                    <li className="vanish fade"><Link to={'/people'}><i className="fas fa-user-friends"></i> PEOPLE</Link></li>
                    <li className="vanish fade"><Link to={'/contact'}><i className="fas fa-envelope"></i> CONTACT</Link></li>
                </ul>
                <div className={menu ? "hamburger active" : "hamburger"} onClick={toggle}></div>
            </nav>
    )
}

export default Nav
