import React from 'react'
import dp from '../../images/dp.jpg'
import Header from './../layout/Header';

function People() {
    return (
        <>
            <Header text="our team" />
            <div class="people-h">
                <p>We are a team of young and enthusiastic designers who believe in delivering meaningful design solutions, encouraging environment and human values.</p>
            </div>
            <div className="people">
                <div class="team-c">
                    <img src={dp} alt="" />
                    <div>
                        <span>Sonika Jain</span>
                        <span>Founder | Principal Architect</span>
                        <span>She completed her undergraduation from Aayojan School of Architecture, Jaipur in bachelor of architecture in year 2016. Completed her masters in design from poornima university, Jaipur in year 2020. As a student, she has achieved various titles and awards for her spatial designs and art work. She has experience from design firms located in jaipur and new delhi. She has worked in various projects in residential, commercial, hospitality, set design, graphic design, exhibition design and various other categories. She is also an interior design faculty in INIFD institute, Jaipur.</span>
                    </div>
                </div>
                <div class="quote"><i class="fas fa-quote-left"></i>Being a designer i believe in bringing functionality and aesthetics together keeping in mind the environmental comfort.<i class="fas fa-quote-right"></i></div>
            </div>
        </>
    )
}

export default People
