import React from "react";

function Images(props) {
  let imgSource =
    process.env.PUBLIC_URL +
    "/" +
    props.category +
    "/" +
    props.pathName +
    "/" +
    props.i +
    ".jpg";

  return (
    <div className="image">
      <img
        src={imgSource}
        alt="project"
        width="300"
        onClick={() => props.setModal(imgSource)}
      />
    </div>
  );
}

export default Images;
