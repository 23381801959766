import React from 'react'
import Banner from './Banner';
import Landing from './Landing';
import OurStory from './OurStory';
import Services from './Services';
import Grid from './Grid';
import Testimonials from './testimonials/Testimonials';

function Home() {
    return (
        <>
            <Landing />
            <OurStory />
            <Banner />
            <Services />
            <Grid />
            <Testimonials />
        </>
    )
}

export default Home