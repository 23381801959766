import React from "react";
import Header from "../layout/Header";
import ProjectTile from "./ProjectTile";
import { ProjectInfo as P } from "./ProjectInfo";

function Projects() {
  return (
    <>
      <Header text="projects" />
      <div className="projects-container">
        <ProjectTile
          url={"commercial/Shades/4.jpg"}
          category={P[0].category}
          pathName={P[0].pathName}
          id={P[0].id}
          class="card-wide"
          desc={P[0].desc}
          name={P[0].name}
        />
        <ProjectTile
          url={"residential/Bharti-Residence/2.jpg"}
          category={P[4].category}
          pathName={P[4].pathName}
          id={P[4].id}
          class="card-tall"
          desc={P[4].desc}
          name={P[4].name}
        />
        <ProjectTile
          url={"commercial/Manukunj-Boutique/2.jpg"}
          category={P[1].category}
          pathName={P[1].pathName}
          id={P[1].id}
          class="card-tall"
          desc={P[1].desc}
          name={P[1].name}
        />
        <ProjectTile
          url={"commercial/Mr.-Dharmendra-Commercial/1.jpg"}
          category={P[3].category}
          pathName={P[3].pathName}
          id={P[3].id}
          class="fifth"
          desc={P[3].desc}
          name={P[3].name}
        />
        <ProjectTile
          url={"commercial/Parth-Publishers/5.jpg"}
          category={P[2].category}
          pathName={P[2].pathName}
          id={P[2].id}
          class="card-tall"
          desc={P[2].desc}
          name={P[2].name}
        />
        <ProjectTile
          url={"residential/Shree-Villa/1.jpg"}
          category={P[9].category}
          pathName={P[9].pathName}
          id={P[9].id}
          class="card-tall"
          desc={P[9].desc}
          name={P[9].name}
        />
        <ProjectTile
          url={"residential/Sharma-Residence/3.jpg"}
          category={P[6].category}
          pathName={P[6].pathName}
          id={P[6].id}
          desc={P[6].desc}
          name={P[6].name}
        />
        <ProjectTile
          url={"residential/Chaturvedi-Residence/6.jpg"}
          category={P[7].category}
          pathName={P[7].pathName}
          id={P[7].id}
          class="card-tall card-wide"
          desc={P[7].desc}
          name={P[7].name}
        />
        <ProjectTile
          url={"residential/Eclectic-House/9.jpg"}
          category={P[8].category}
          pathName={P[8].pathName}
          class="card-tall"
          id={P[8].id}
          desc={P[8].desc}
          name={P[8].name}
        />

        <ProjectTile
          url={"residential/house-no-92/9.jpg"}
          category={P[10].category}
          pathName={P[10].pathName}
          id={P[10].id}
          class="card-wide"
          desc={P[10].desc}
          name={P[10].name}
        />
        <ProjectTile
          url={"residential/Jagdish-Sharma-Residence/1.jpg"}
          category={P[5].category}
          pathName={P[5].pathName}
          id={P[5].id}
          desc={P[5].desc}
          name={P[5].name}
        />
        <ProjectTile
          url={"residential/pastels-splash/6.jpg"}
          category={P[13].category}
          pathName={P[13].pathName}
          class="card-tall card-wide"
          id={P[13].id}
          desc={P[13].desc}
          name={P[13].name}
        />
        <ProjectTile
          url={"residential/panashe/8.jpg"}
          category={P[12].category}
          pathName={P[12].pathName}
          id={P[12].id}
          desc={P[12].desc}
          name={P[12].name}
        />
        <ProjectTile
          url={"residential/mid-century-abode/21.jpg"}
          category={P[11].category}
          pathName={P[11].pathName}
          id={P[11].id}
          desc={P[11].desc}
          name={P[11].name}
        />
      </div>
    </>
  );
}

export default Projects;
