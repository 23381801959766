import React, {useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import './css/style.css'
import Home from './components/home/Home'  
import Projects from './components/projects/Projects'  
import People from './components/people/People'
import Contact from './components/contact/Contact'  
import Nav from './components/layout/Nav'
import Footer from './components/layout/Footer'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ProjectPage from './components/projects/ProjectPage'
import ScrollToTop from './components/layout/ScrollToTop';


function App() {

  useEffect(() => {
    AOS.init({duration : 1500});
  },[])

  return (
    <Router>
        <ScrollToTop />
        <Nav />
          <Switch>
              <Route path='/' exact component={Home} />
              <Route path='/projects' component={Projects} />
              <Route path='/people' component={People} />
              <Route path='/contact' component={Contact} />
              <Route path='/:category/:name/:id' exact component={ProjectPage} />
          </Switch>
        <Footer /> 
    </Router>
  )
}

export default App;
