import React, {useRef} from 'react'
import axios from 'axios'
import FormInput from './FormInput';
import { useForm } from 'react-hook-form'

// const API_PATH = 'http://localhost/webdev/Techodecs/api/email-controller.php'
const API_PATH = '/api/email-controller.php'

function Form() {

    const { register, errors, handleSubmit } = useForm()
    const formRef = useRef(null)
    const submitRef = useRef(null)

    const onSubmit = data => {

        const formData = new FormData();
        formData.append('name',data.firstn + data.lastn)
        formData.append('email',data.email)
        formData.append('mobile',data.mobile)
        formData.append('message',data.message)

        axios.post(API_PATH, formData)
        .then(() => {
                formRef.current.reset()
                submitRef.current.value="SENT SUCCESSFULLY"
                submitRef.current.style.width="155px"
                submitRef.current.style.background="#48FF55"
            }
        )
    }

    return (
        <div className="contact-form">
            <div className="container">
                <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                    <div className="row100">
                        <FormInput name="firstn" reference={register} text="First Name" />
                        <FormInput name="lastn" reference={register} text="Last Name" />
                    </div>
                    <div className="row100">
                        <FormInput name="email" reference={register({pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/})} text="Email" error={errors.email} errorName="*Invalid Email"/>
                        <FormInput name="mobile" reference={register({pattern:/^\d{10}$/})} text="Mobile" error={errors.mobile} errorName="*Invalid Mobile"/></div>
                    <div className="row100">
                        <FormInput name="message" text="Your Message" type="textarea" reference={register} />
                    </div>
                    <div className="row100">
                        <div className="col">
                            <input type="submit" id="send" value="Send" ref={submitRef}/>
                        </div>
                    </div>
                </form>	
            </div>
        </div>
    )
}

export default Form