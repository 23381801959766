import React from 'react'

function Banner() {
    return (
        <div className="banner">
            <div>
                <span>30+</span>
                <span>Projects Completed</span>
            </div>
            <div>
                <span>25+</span>
                <span>Happy Clients</span>
            </div>
            <div>
                <span>4+</span>
                <span>Years of Experience</span>
            </div>
        </div>
    )
}

export default Banner
