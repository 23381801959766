import React from 'react'

function OurStory() {
    return (
        <>
        <span id="about"></span>
        <div className="our-story">
            <h2>Our Story</h2>
            <span>Since 2017, we have been successfully paving our way towards fabricating distinct spaces by wielding our unique expertise in the arena of architecture and interiors .</span>
            <span>By using newest ways of executing a project, which involves a string of actions starting all the way from contemplation of clients' requirements to the final implementation & deliverance; we have been profitably acing out every other competitor in the market.</span>
        </div>
        </>
    )
}

export default OurStory
